<template>
  <v-container fluid class="content-wrapper">
    <div class="row match-height">
      <h4 class="fw-600 text-mb-2">
        {{ $t("SigninUserAndRole.create_user") }}
      </h4>

      <div class="col-md-12">
        <!-- Recent Booking -->
        <div class="row">
          <div id="recent-transactions" class="col-12">
            <div class="card">
              <div class="card-content p-2">
                <form class="form form-horizontal">
                  <div class="form-body">
                    <div class="row">
                      <label
                        class="col-md-3 label-control text-right"
                        for="projectinput1"
                      >
                        <b>Email <span class="align-top">*</span></b>
                      </label>
                      <div class="col-md-9 mx-auto">
                        <input
                          type="text"
                          id="projectinput1"
                          class="form-control"
                          placeholder="test@gmail.com"
                          name="fname"
                        />
                        <small class="text-muted">The email of user.</small>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <label
                        class="col-md-3 label-control text-right"
                        for="projectinput1"
                      >
                        <b>Full Name <span class="align-top">*</span></b>
                      </label>
                      <div class="col-md-9 mx-auto">
                        <input
                          type="text"
                          id="projectinput1"
                          class="form-control"
                          placeholder="Full Name"
                          name="fname"
                        />
                        <small class="text-muted">The full name of user.</small>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <label
                        class="col-md-3 label-control text-right"
                        for="projectinput2"
                        ><b>Avatar</b></label
                      >
                      <div class="col-md-9 mx-auto">
                        <div class="card box-image mb-0">
                          <div class="card-body">
                            <div class="media">
                              <div class="media-body mt-75">
                                <div
                                  class="
                                    col-12
                                    px-0
                                    d-flex
                                    flex-sm-row flex-column
                                    justify-content-start
                                  "
                                >
                                  <label
                                    class="btn btn-info cursor-pointer"
                                    for="account-upload"
                                    >Upload a file</label
                                  >
                                  <input
                                    type="file"
                                    id="account-upload"
                                    hidden=""
                                    accept="image/*"
                                    onchange="loadFile(event)"
                                    name="myfile"
                                  />
                                </div>
                                <div class="output-image">
                                  <img
                                    src="app-assets/images/portrait/small/avatar-s-5.png"
                                    alt=""
                                    srcset=""
                                    style="border-radius: 60px"
                                  />
                                  <span>default.png</span>
                                  <i class="la la-close"></i>
                                  <!--<img id="output" class="" style="width: 200px; height: auto; margin-top: 16px;">-->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <small class="text-muted"
                          >Format file must PNG,JPEG.</small
                        >
                      </div>
                    </div>

                    <div class="row mt-2">
                      <label
                        class="col-md-3 label-control text-right"
                        for="projectinput3"
                        ><b>Password</b></label
                      >
                      <div class="col-md-9 mx-auto">
                        <fieldset class="form-group position-relative">
                          <input
                            type="password"
                            class="form-control form-control-lg input-lg"
                            id="password-field"
                            placeholder="Password"
                            value="1234"
                          />
                          <div class="form-control-position">
                            <span
                              toggle="#password-field"
                              class="la icon-eye field-icon toggle-password"
                            ></span>
                          </div>
                          <small class="text-muted">
                            Do not fill in if you do not want to change the
                            password, <br />
                            the password charater must 6 or more.
                          </small>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <a
                    @click="goBackFunc"
                    class="btn btn-warning btn-min-width mb-1 mr-3"
                    >ย้อนกลับ</a
                  >
                  <a
                    @click="saveFunc"
                    class="btn btn-primary btn-min-width mb-1"
                    >บันทึก</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--/ Recent Booking -->
      </div>
    </div>
  </v-container>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";

export default {
  name: "SigninUserScreen",
  metaInfo: {
    title: "User",
    titleTemplate: "%s | Aigent",
  },
  data: () => ({
    userToken: "",
    propertyID: "",
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
  },
  methods: {
    goBackFunc() {
      const self = this;
      EventBus.$emit("loadingtillend");
      const link = {
        name: "SigninUserScreen",
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
        },
      };
      EventBus.$emit("changePathname", link);
      EventBus.$emit("endloading");
    },
    saveFunc() {
      const self = this;
      EventBus.$emit("loadingtillend");
      const link = {
        name: "SigninUserScreen",
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
        },
      };
      swal(
        self.$t("Alert.success_title"),
        self.$t("Alert.save_success"),
        self.$t("Alert.success_label"),
        {
          button: false,
          timer: 2000,
        }
      ).then(() => {
        EventBus.$emit("changePathname", link);
        EventBus.$emit("endloading");
      });
    },
  },
};
</script>

<style scoped>
@import "../../../../styles/signin/holiday.css";
@import "../../../../styles/signin/rateplan.css";
</style>

<style lang="scss" scoped>
@import "../../../../styles/bootstrap/global.css";
@import "../../../../styles/signin/setting.css";

.border-pink-focus {
  border-color: var(--pink) !important;
}
</style>
